export const TableConfig = {
    tables: [
        {
            name: "Tenants",
            items: [
                { name: "Tenant Id", id: "tenant_id", type: "default", displayTable: true },
                { name: "Tenant Name", id: "tenant_name", type: "default", displayTable: true },
                { name: "Tenant Code", id: "tenant_code", type: "default", displayTable: true },
                { name: "Contact Name", id: "primary_contact_name", type: "default", displayTable: true },
                { name: "Contact Phone", id: "primary_contact_phone", type: "default", displayTable: true },
                { name: "Contact Email", id: "primary_contact_email", type: "default", displayTable: false },
                { name: "Created by", id: "created_by", type: "default", displayTable: false },
                { name: "Created Time", id: "created_time", type: "time", displayTable: true, sort: true },
                { name: "Status", id: "is_active", type: "toggle", displayTable: true },
                { name: "Source", id: "source", type: "default", displayTable: false },
                { name: "Grades", id: "grades", type: "default", displayTable: false },
                { name: "Products", id: "products", type: "default", displayTable: false },
                { name: "Image", id: "image", type: "image", displayTable: false },
            ],
        },
        {
            name: "Schools",
            items: [
                { name: "School Id", id: "school_id", type: "default", displayTable: true },
                { name: "School Name", id: "school_name", type: "default", displayTable: true },
                { name: "Product", id: "product_id", type: "default", displayTable: false },
                { name: "Tenant", id: "tenant_id", type: "default", displayTable: true },
                { name: "Email", id: "email", type: "default", displayTable: false },
                { name: "First Name", id: "first_name", type: "default", displayTable: false },
                { name: "Last Name", id: "last_name", type: "default", displayTable: false },
                { name: "Phone", id: "phone", type: "default", displayTable: true },
                { name: "Country", id: "country_name", type: "default", displayTable: false },
                { name: "State", id: "state_name", type: "default", displayTable: false },
                { name: "City", id: "city_name", type: "default", displayTable: false },
                { name: "Pincode", id: "pincode", type: "default", displayTable: false },
                { name: "Created Time", id: "created_at", type: "time", displayTable: true },
                { name: "Status", id: "is_active", type: "toggle", displayTable: true },
            ],
        },
        {
            name: "Grades",
            items: [
                { name: "Grade Id", id: "grade_id", type: "default", displayTable: true },
                { name: "Grade Name", id: "grade_name", type: "default", displayTable: true },
                { name: "Description", id: "description", type: "default", displayTable: true },
                { name: "Image", id: "image", type: "image", displayTable: true },
                { name: "Products", id: "products", type: "default", displayTable: false },
                { name: "Created Time", id: "created_time", type: "time", displayTable: true },
                { name: "Status", id: "is_active", type: "toggle", displayTable: true },
            ],
        },
        {
            name: "Products",
            items: [
                { name: "Product Id", id: "product_id", type: "default", displayTable: true },
                { name: "Product Name", id: "product_name", type: "default", displayTable: true },
                { name: "Product Code", id: "product_code", type: "default", displayTable: true },
                { name: "Description", id: "description", type: "default", displayTable: true },
                { name: "Created Time", id: "created_at", type: "time", displayTable: true },
                { name: "Status", id: "is_active", type: "toggle", displayTable: true },
                { name: "Logo", id: "logo", type: "image", displayTable: false },
                { name: "Endpoint URL", id: "endpoint_url", type: "default", displayTable: false },
            ],
        },
        {
            name: "Roles",
            items: [
                { name: "Role Id", id: "role_id", type: "default", displayTable: true },
                { name: "Role Name", id: "role_name", type: "default", displayTable: true },
                { name: "Display Name", id: "display_name", type: "default", displayTable: true },
                { name: "Role Code", id: "role_code", type: "default", displayTable: true },
                { name: "Created Time", id: "created_time", type: "time", displayTable: true },
                { name: "Status", id: "active", type: "toggle", displayTable: true },
            ],
        },
        {
            name: "Exams",
            items: [
                { name: "Exam ID", id: "exam_id", type: "default", displayTable: true },
                { name: "Exam Name", id: "name", type: "default", displayTable: true },
                { name: "Exam Logo", id: "image", type: "image", displayTable: true },
                { name: "Description", id: "description", type: "default", displayTable: true },
                { name: "Created Time", id: "created_time", type: "time", displayTable: true },
                { name: "Status", id: "active", type: "toggle", displayTable: true },
            ],
        },
        {
            name: "Users",
            items: [
                { name: "Admission Number", id: "admission_number", type: "default", displayTable: true },
                // { name: "UAM Id", id: "uam_id", type: "default", displayTable: true },
                { name: "First Name", id: "first_name", type: "default", displayTable: true },
                { name: "Last Name", id: "last_name", type: "default", displayTable: true },
                { name: "Phone", id: "phone", type: "default", displayTable: true },
                { name: "Email", id: "email", type: "default", displayTable: false },
                { name: "Product", id: "product_id", type: "default", displayTable: false },
                { name: "Tenant", id: "tenant_id", type: "default", displayTable: false },
                { name: "Grade", id: "grade_id", type: "default", displayTable: false },
                // { name: "Grade Name", id: "grade_name", type: "default", displayTable: false },
                { name: "CRN Id", id: "crn_id", type: "default", displayTable: false },
                { name: "School Id", id: "school_id", type: "default", displayTable: true },
                { name: "Hall ticket Id", id: "hall_ticket_id", type: "default", displayTable: true },
                { name: "Created Time", id: "created_at", type: "time", displayTable: false },
                { name: "Status", id: "is_active", type: "toggle", displayTable: true },
            ],
        },
        {
            name: "Programs",
            items: [
                { name: "Program Id", id: "program_id", type: "default", displayTable: true },
                { name: "Program Name", id: "program_name", type: "default", displayTable: true },
                { name: "Exam Type", id: "exam_type", type: "default", displayTable: true },
                { name: "Products", id: "product_ids", type: "default", displayTable: false },
                { name: "Grades", id: "grade_ids", type: "default", displayTable: false },
                { name: "Created Time", id: "created_time", type: "time", displayTable: true },
                { name: "Status", id: "is_active", type: "toggle", displayTable: true },
            ],
        },
        {
            name: "Agents",
            items: [
                { name: "UAM ID", id: "uam_id", type: "default", displayTable: true },
                { name: "First Name", id: "first_name", type: "default", displayTable: true },
                { name: "Last Name", id: "last_name", type: "default", displayTable: true },
                { name: "Description", id: "description", type: "default", displayTable: false },
                { name: "Admission Number", id: "admission_number", type: "default", displayTable: true },
                // { name: "CRN Id", id: "crn_id", type: "default", displayTable: false },
                { name: "Email", id: "email", type: "default", displayTable: false },
                { name: "Phone", id: "phone", type: "default", displayTable: true },
                { name: "Created Time", id: "created_at", type: "time", displayTable: true },
                { name: "Status", id: "is_active", type: "toggle", displayTable: true },
                { name: "Role", id: "roles", type: "default", displayTable: false },
                { name: "Gender", id: "gender", type: "default", displayTable: false },
                { name: "Date of birth", id: "dob", type: "default", displayTable: false },
                { name: "Profile Photo", id: "profile_photo", type: "image", displayTable: false },
            ],
        },
    ],
};
