import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Main } from "../Main";
import DynamicSnackBar from "../SnackBar";
import {
    Tenants,
    Grades,
    Users,
    Schools,
    Products,
    Roles,
    Exams,
    Agents,
    Programs,
    UsersBulkUpload,
    UploadHistory,
    SingleJobDetail,
} from "../../pages";
import { GetRoles, getAgentDTO, getDashboardCount, getExams, getGrades, getProducts, getTenantData } from "../../utils";
import { CommonApiDataContext } from "../../context/CommonApiDataContext";
import MainDashboard from "../cards/Dashboard";

const prods = [
    { label: "Learn", value: 300 },
    { label: "KHub", value: 700 },
    { label: "Meta", value: 200 },
    { label: "MetaJr", value: 600 },
    { label: "Inmobius", value: 100 },
    { label: "IL Schools", value: 500 },
];

const Dashboard = ({ children, pageTitle }) => {
    // State management for various data
    const [snackbarData, setSnackbarData] = useState({ open: false, msg: "", type: "" });
    const [roles, setRoles] = useState([]);
    const [products, setProducts] = useState([]);
    const [grades, setGrades] = useState([]);
    const [exams, setExams] = useState([]);
    const [tenants, setTenants] = useState([]);
    const [keys, setKeys] = useState([]);
    const [access, setAccess] = useState(null);
    const [dashboardData, setDashboardData] = useState({});
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    // Function to handle snackbar visibility and message
    const handleOpenSnackbar = useCallback((open, msg, type) => {
        setSnackbarData({ open, msg, type });
    }, []);

    // Function to redirect user to login page
    const pushToLogin = useCallback(() => {
        localStorage.setItem("pushed_to_login", "true");
        localStorage.removeItem("sessionStart");
        localStorage.removeItem("user_data");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("access");
        history.push("/login");
    }, [history]);

    useEffect(() => {
        const agentAccess = localStorage.getItem("access");
        agentAccess ? setAccess(agentAccess) : handleOpenSnackbar(true, "Role missing, please login again!", "error");
    }, [handleOpenSnackbar]);

    // Fetching data based on page title
    useEffect(() => {
        const agentAccess = localStorage.getItem("access");
        agentAccess ? setAccess(agentAccess) : handleOpenSnackbar(true, "Role missing, please login again!", "error");
        if (!agentAccess) {
            pushToLogin();
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch data concurrently
                const [dashboardCounts, fetchedRoles, fetchedProducts, fetchedGrades, fetchedExams, fetchedTenants] =
                    await Promise.all([
                        pageTitle === "Dashboard" && getDashboardCount(localStorage.getItem("accessToken")),
                        GetRoles(1, 100),
                        pageTitle !== "Users" && getProducts(1, 100),
                        getGrades(),
                        getExams(localStorage.getItem("accessToken"), 1, 100),
                        (pageTitle === "Schools" || pageTitle === "Users") && getTenantData(),
                    ]);

                // Helper function to format data
                const getUpdatedList = (list, name, id, active, source) => {
                    return list?.map((item) => ({
                        label: item[name],
                        value: item[id],
                        active: item[active],
                        source: item[source],
                    }));
                };

                // Set fetched data to state
                setDashboardData(dashboardCounts);

                if (pageTitle === "Schools" || pageTitle === "Users") {
                    setTenants(getUpdatedList(fetchedTenants, "tenant_name", "tenant_id", "is_active", "source"));
                }

                setRoles(getUpdatedList(fetchedRoles?.roles, "role_name", "role_id", "active"));

                const updatedProducts = getUpdatedList(
                    fetchedProducts?.products,
                    "product_name",
                    "product_id",
                    "is_active"
                );
                setProducts(pageTitle === "Users" ? prods : updatedProducts);

                setGrades(getUpdatedList(fetchedGrades?.grades, "grade_name", "grade_id", "is_active"));
                setExams(getUpdatedList(fetchedExams?.exams, "name", "exam_id", "active"));
                setLoading(false);
            } catch (error) {
                handleOpenSnackbar(true, error?.response?.data?.detail, "error");
                if (["Expired Signature", "Unauthorised", "Missing Token"].includes(error?.response?.data?.detail)) {
                    setTimeout(() => pushToLogin(), 3000);
                }
                setLoading(false);
                console.log(error);
            }
        };

        // Fetch data if the page title is not one of the excluded ones
        if (
            !["Exams", "Roles", "Products", "Users Bulk upload", "Users Upload History", "Single Job Detail"].includes(
                pageTitle
            )
        ) {
            fetchData();
        }
    }, [pageTitle, handleOpenSnackbar, pushToLogin]);

    // Fetch user data and determine user access level
    // useEffect(() => {
    //     const getUserDto = async () => {
    //         setLoading(true);
    //         try {
    //             const userDTO = await getAgentDTO(localStorage.getItem("accessToken"));
    //             const roleIds = userDTO[0].roles.map((role) => role.roleId);
    //             const roleNames = userDTO[0].roles.map((role) => role.roleName);
    //             const roleCodes = userDTO[0].roles.map((role) => role.roleCode);

    //             const isAdmin = (list) =>
    //                 list.map((role) => role.toLowerCase()).some((keyword) => keyword.includes("admin"));

    //             const superAdmin = roleIds.includes(5);
    //             const admin = isAdmin(roleNames);
    //             const inmobiusUser = roleCodes.some((keyword) => keyword.includes("IMR"));
    //             const inmobiusSuperAdmin = roleCodes.some((keyword) => keyword.includes("ISA"));

    //             if (superAdmin && admin) {
    //                 setAccess("superAdmin");
    //             } else if (inmobiusSuperAdmin) {
    //                 setAccess("inmobiusSuperAdmin");
    //             } else if (superAdmin) {
    //                 setAccess("superAdmin");
    //             } else if (admin) {
    //                 setAccess("admin");
    //             } else if (inmobiusUser) {
    //                 setAccess("inmobiusUser");
    //             } else {
    //                 setAccess("other");
    //             }
    //             setLoading(false);
    //         } catch (error) {
    //             setLoading(false);
    //             handleOpenSnackbar(true, error?.response?.data?.detail, "error");
    //             if (["Expired Signature", "Unauthorised", "Missing Token"].includes(error?.response?.data?.detail)) {
    //                 setTimeout(() => pushToLogin(), 3000);
    //             }
    //             console.log(error);
    //         }
    //     };

    //     getUserDto();
    // }, [pageTitle, handleOpenSnackbar, pushToLogin]);

    // Memoize context value to avoid unnecessary re-renders
    const contextValue = useMemo(
        () => ({
            dashboardData,
            roles,
            products,
            grades,
            exams,
            tenants,
            keys,
            access,
            loading,
            setKeys,
            handleOpenSnackbar,
            setAccess,
            pushToLogin,
        }),
        [dashboardData, roles, products, grades, exams, tenants, keys, access, loading, handleOpenSnackbar, pushToLogin]
    );

    return (
        <CommonApiDataContext.Provider value={contextValue}>
            <main id="main" className="main">
                <Main />
                {children}
                <DynamicSnackBar {...snackbarData} />
            </main>
        </CommonApiDataContext.Provider>
    );
};

// Individual dashboards for different modules
const ExamsDashboard = () => (
    <Dashboard pageTitle="Exams">
        <Exams />
    </Dashboard>
);

const GradesDashboard = () => (
    <Dashboard pageTitle="Grades">
        <Grades />
    </Dashboard>
);

const ProductDashboard = () => (
    <Dashboard pageTitle="Products">
        <Products />
    </Dashboard>
);

const SchoolDashboard = () => (
    <Dashboard pageTitle="Schools">
        <Schools />
    </Dashboard>
);

const TenantDashboard = () => (
    <Dashboard pageTitle="Tenants">
        <Tenants />
    </Dashboard>
);

const UsersDashboard = () => (
    <Dashboard pageTitle="Users">
        <Users />
    </Dashboard>
);

const UserBulkUploadDashboard = () => (
    <Dashboard pageTitle="Users Bulk upload">
        <UsersBulkUpload />
    </Dashboard>
);

const UsersUploadHistoryDashboard = () => (
    <Dashboard pageTitle="Users Upload History">
        <UploadHistory />
    </Dashboard>
);

const SingleJobDetailDashboard = () => (
    <Dashboard pageTitle="Single Job Detail">
        <SingleJobDetail />
    </Dashboard>
);

const ProgramsDashboard = () => (
    <Dashboard pageTitle="Programs">
        <Programs />
    </Dashboard>
);

const AgentsDashboard = () => (
    <Dashboard pageTitle="Agents">
        <Agents />
    </Dashboard>
);

const RolesDashboard = () => (
    <Dashboard pageTitle="Roles">
        <Roles />
    </Dashboard>
);

const MDashboard = () => (
    <Dashboard pageTitle="Dashboard">
        <MainDashboard />
    </Dashboard>
);

export {
    ExamsDashboard,
    GradesDashboard,
    ProductDashboard,
    SchoolDashboard,
    TenantDashboard,
    AgentsDashboard,
    UsersDashboard,
    ProgramsDashboard,
    RolesDashboard,
    MDashboard,
    UserBulkUploadDashboard,
    UsersUploadHistoryDashboard,
    SingleJobDetailDashboard,
};
