import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { isAuthenticated } from "../../../utils/auth";
import { getUsers, updateUser } from "../../../utils";
import Table from "../../table/Table";
import { CommonApiDataContext } from "../../../context/CommonApiDataContext";
import BulkUploadBtn from "../../../components/buttons/BulkUploadBtn";
import { moduleConfig } from "../../../config";
import { CONFIG_VARS } from "../../../config/globleConfig";
import { GetSearchParams } from "../../../utils/reusedMethods";
import UserWrapper from "./UserWrapper";

const Users = () => {
    const [data, setData] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(20);
    const [loading, setLoading] = useState(true);
    const [productId, setProductId] = useState(null);
    const [search, setSearch] = useState({ searchText: null, searchBy: moduleConfig.users?.searchableItems[0]?.value });

    const history = useHistory();
    const searchParams = GetSearchParams();
    const accessToken = localStorage.getItem("accessToken");

    const { products, tenants, grades, handleOpenSnackbar, setKeys, access } = useContext(CommonApiDataContext);

    useEffect(() => {
        document.title = "Gateway Admin | Users";
        if (!isAuthenticated()) {
            history.push("/login");
        }
    }, [history]);

    // useEffect(() => {
    //     setProductId((products && products[0]?.value) || null);
    // }, [products]);

    /*@TODO: need remove in final stage */
    useEffect(() => {
        /*@TODO: need remove in final stage */
        const getProds = () => {
            if (access && access.includes("inmobius")) {
                return products.filter((p) => p.value === CONFIG_VARS.inmobiusProdId);
            } else if (access && access === "learnSuperAdmin") {
                return products.filter((p) => CONFIG_VARS.learnSuperAdminProds.includes(Number(p.value)));
            } else if (access && access === "ILSchoolsSuperAdmin") {
                return products.filter((p) => CONFIG_VARS.ILSchoolsSuperAdminProds.includes(Number(p.value)));
            } else {
                return products;
            }
        };
        const inmobiusProd = getProds();
        const product_id = searchParams.get("product_id");

        product_id ? setProductId(product_id) : setProductId((inmobiusProd && inmobiusProd[0]?.value) || "");
    }, [access, products, searchParams]);

    useEffect(() => {
        setKeys({ prod: "product_id", tnt: "tenant_id", grad: "grade_id" });
    }, [setKeys]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const userData = await getUsers(accessToken, currPage, limit, productId, search.searchText);
                const updatedData = userData?.users?.map((item) => ReplaceIdsWithLabels(item));

                setCurrPage(userData?.currentPage);
                setTotalPages(userData?.totalPages);
                setData(updatedData);
                setLoading(false);
            } catch (error) {
                handleOpenSnackbar(true, error?.response?.data?.detail, "error");
                setLoading(false);
                console.log(error);
            }
        };
        CONFIG_VARS.bulkuploadAllowedProds.includes(Number(productId)) && localStorage.setItem("productId", productId);
        productId && fetchData();
    }, [currPage, limit, productId]);

    const ReplaceIdsWithLabels = (item) => {
        const UP = products?.filter((e) => e.value === Number(item?.product_id))[0]?.label;
        const UG = grades?.filter((e) => e.value === Number(item?.grade_id))[0]?.label;
        // const UT = tenants?.filter(
        //     (e) => e.value === Number(item?.tenant_id) && productId === "200" && e.source === "IM"
        // )[0]?.label;

        const NUT = tenants?.filter((e) => {
            if (productId === "200") {
                if (e.value === Number(item?.tenant_id) && e.source === "IM") {
                    return e;
                }
            } else if (e.value === Number(item?.tenant_id) && e.source !== "IM") {
                return e;
            }
            // return;
        });

        return { ...item, product_id: UP, tenant_id: NUT[0]?.label, grade_id: UG };
    };

    const onUpdateUser = async (formData) => {
        try {
            const res = await updateUser(accessToken, formData);
            const UUD = data.map((e) => {
                if (e?.admission_number === res?.admission_number) {
                    return ReplaceIdsWithLabels(res);
                }
                return e;
            });
            setData(UUD);
        } catch (error) {
            handleOpenSnackbar(true, error?.message, "error");
        }
    };

    const onSearchUsers = async () => {
        setLoading(true);
        try {
            const searchedData = await getUsers(accessToken, currPage, limit, productId, search.searchText);
            const updatedData = searchedData?.users?.map((user) => ReplaceIdsWithLabels(user));
            setData(updatedData);
            setCurrPage(1);
            setTotalPages(1);
            setLoading(false);
        } catch (error) {
            handleOpenSnackbar(true, error?.response?.data?.detail, "error");
            setLoading(false);
        }
    };

    const handleToggle = (item) => {
        let chngIsAtvTo = null;

        const updatedList = data.map((e) => {
            if (e?.uam_id === item?.uam_id) {
                const isActive = item?.is_active;
                chngIsAtvTo = !isActive;
                return {
                    ...e,
                    is_active: !isActive,
                };
            }
            return e;
        });
        setData(updatedList);
        const ids = {
            user_ids: [item?.uam_id],
        };
    };

    return (
        <section className="section">
            <div className="title-createbtn-box">
                <h1 className="title">Users</h1>
                <div>
                    {CONFIG_VARS.bulkuploadAllowedProds.includes(Number(productId)) && (
                        <BulkUploadBtn text="Bulk Upload" link="/users-bulk-upload" />
                    )}
                </div>
            </div>
            <Table
                navigationId={6}
                pageTitle="Users"
                data={data}
                loading={loading}
                onToggle={handleToggle}
                setCurrPage={setCurrPage}
                setTotalPages={setTotalPages}
                currPage={currPage}
                totalPages={totalPages}
                limit={limit}
                setLimit={setLimit}
                updateItem={onUpdateUser}
                EditForm={UserWrapper}
                setProductIds={setProductId}
                setData={setData}
                setSearch={setSearch}
                search={search}
                onSearch={onSearchUsers}
            />
        </section>
    );
};

export default Users;
